import { Link } from "react-router-dom"

type props = {
    name : string,
    position : string
    image : string
}

export default function TeamMemberCard({name, position, image} : props){
    return (
        <div className="bg-white shadow-sm w-full sm:w-96 md:w-72 rounded-xl rounded-t-[5rem] overflow-hidden pb-10 text-center">
            <img className="w-full h-80 md:h-64 object-cover object-center rounded-br-[5rem]" src={image} alt={`SSA/DRC team member ${name}`} />
            <h3 className="text-default mt-6 font-semibold text-xl">{name}</h3>
            <h4 className="mt-2 mb-4 text-lg text-slate-400">{position}</h4>
            <div className="mt-8 flex gap-6 justify-center text-primary text-2xl">
                <Link to="" target="_blank" className="fab fa-facebook" />
                <Link to="" target="_blank" className="fab fa-twitter" />
                <Link to="" target="_blank" className="fab fa-instagram" />
                <Link to="" target="_blank" className="fab fa-linkedin" />
            </div>
        </div>
    )
}
