/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Container from './container';
import Logo from './logo';
import NewsLetter from "./newsletter";

export default function Footer(){

    return (
        <>
            <NewsLetter />
            <footer className="w-full bg-cover bg-bottom py-8 relative after:absolute after:inset-0 after:bg-black after:bg-opacity-90 text-white" style={{ backgroundImage : "url(/images/smart-sports-agency-all-sport.jpg)"}}>
                <Container className="flex flex-col gap-y-4 relative z-20 py-3">
                    <nav className="flex flex-col md:flex-row items-center text-primary md:text-white gap-4 md:gap-x-6 w-fit mx-auto font-semibold">
                        <Link className="py-1 hover:text-primary-deep" to="/">Accueil</Link>
                        <Link className="py-1 hover:text-primary-deep" to="#about-us">A propos</Link>
                        <Link className="py-1 hover:text-primary-deep" to="#services">Services</Link>
                        <Link className="py-1 hover:text-primary-deep" to="#contact">Contact</Link>
                    </nav>
                    <div className="flex flex-wrap gap-y-5 justify-center md:justify-between items-center sm:inline bg">
                        <div className="flex flex-col items-center gap-y-2  md:gap-y-5">
                            <a href="tel:+243844952771" className="flex flex-col items-center gap-y-5">
                                <i className="fa-solid fa-phone text-3xl text-white" />
                                <span className="hover:text-primary-deep">+243 844 952 771</span>
                            </a>
                            {/*<i className="text-3xl fa-solid fa-location-dot" />*/}

                        </div>
                        {/*<Logo whiteOne className="hidden md:block" />*/}
                        <a href="mailto:contact@ssadrc.com" className="flex flex-col items-center gap-y-2 md:gap-y-5">
                            <i className="fa-regular fa-envelope text-3xl " />
                            <span className="hover:text-primary-deep">contact@ssadrc.com</span>
                        </a>
                    </div>
                    <div className="my-2 md:my-0 flex items-center justify-center gap-6">
                        <a className="fab fa-facebook-f hover:text-primary-deep" target="_blank" href="https://www.facebook.com/profile.php?id=100088092972198&mibextid=LQQJ4d" rel="noreferrer" />
                        <a className="fab fa-twitter hover:text-primary-deep" target="_blank" href="" />
                        <a className="fab fa-instagram hover:text-primary-deep" target="_blank" href="https://l.facebook.com/l.php?u=https%3A%2F%2Fwww.instagram.com%2Fsmartsportagency%3Ffbclid%3DIwAR0NvLUVLS-ZMK5mcCX6CwSPKe1W5ZwivDjIibNzMdgVG7dztuuKQHxbPpg&h=AT1k_90y_wUSmY5S8u12csC9YKk7iXLDt2T4498KC_WpskpG14iZ-19LrB9sApRXShwFzI43r6SVuBJ9C626S4v9xdvnf10pStsmE9f01B_wJZvTz-yaaJWcDxyKeIeLWsgf" rel="noreferrer" />
                        <a className="fab fa-linkedin-in hover:text-primary-deep" target="_blank" href="https://www.linkedin.com/company/smart-sport-agency" rel="noreferrer" />
                    </div>
                    <div className="flex flex-wrap gap-y-5 justify-center md:justify-between items-center">
                        <p className="text-center text-sm mt-1">
                           ID NAT : <span className="text-primary font-semibold italic">01-H5300-N23097T</span>
                        </p>
                        <p className="text-center text-sm mt-1">
                           RCCM : <span className="text-primary font-semibold italic">CD/KNG/RCCM/22-B-03520</span>
                        </p>
                    </div>
                    <p className=" text-gray-500 text-center text-sm mt-4">
                         <i className="fa-regular fa-copyright" /> { new Date(Date.now()).getFullYear() } SSA DRC | Conçu <a className="text-gray-400 hover:text-primary-deep font-semibold" href={"https://diginco.com"} target={"__blank"}>Diginco</a>
                    </p>
                </Container>
            </footer>
        </>
    )
}
