import { Link } from "react-router-dom";
import { ClientLogo, ServiceCard, TeamMemberCard } from "../components";
import Container from "../components/container";
import MyForm, { InputFieldCustomSelect, InputFieldTextArea } from "../components/my-form";
import Slider from "../components/slider";
import emailjs, {EmailJSResponseStatus} from "@emailjs/browser"
import {useState} from "react";

export default function Home() {
    const [isSubmitting, setIsSubmitting] = useState(false)
    return (
        <>
            {/* hero-main section */}
            <section className="relative bg-slate-100 h-screen lg:h-[600px]">
                <div className="absolute inset-0 md:grid md:grid-cols-2 lg:grid-cols-4">
                    <img className="hidden md:block object-cover object-center h-full w-full" src="/images/smart-sport-agency-istockphoto-475077996-612x612.jpg" alt="" />
                    <img className="hidden md:block object-cover object-center h-full w-full" src="/images/smart-sport-agency-istockphoto-1266971680-612x612.jpg" alt="" />
                    <img className="hidden lg:block object-cover object-center h-full w-full" src="/images/smart-sports-agency-competition.jpg" alt="" />
                    <img className="hidden lg:block object-cover object-center h-full w-full" src="/images/smart-sports-agency-judo.jpg" alt="" />
                    <img className="md:hidden object-cover object-center h-full w-full" src="/images/smart-sport-agency-football-5587398_1920.jpg" alt="" />
                </div>
                <div className="flex items-center text-white relative z-10 w-full bg-black bg-opacity-50 h-full py-14">
                    <Container className="text-center md:text-left">
                        <h1 className="text-4xl lg:text-7xl font-bold">SSA DRC</h1>
                        <p className="text-lg max-w-xl my-4">
                            Cabinet d’étude et de conseil en sport basé en République Démocratique du Congo avec un important réseau d'affaires en Afrique, en Europe et dans le reste du monde.
                        </p>
                        <Link className="block mx-auto md:mx-0 w-fit bg-primary hover:bg-secondary rounded-3xl px-8 py-2 text-lg" to="#contact">Nous contacter</Link>
                    </Container>
                </div>
            </section>

            {/* about area starts */}
            <section id="about-us" >
                <Container className="py-28 flex flex-col gap-y-8">
                    <div className="text-default grid sm:grid-cols-2 gap-x-10 items-center">
                        <div className="h-full w-full relative hidden md:block">
                            <img className="absolute right-0 bottom-0 translate-y-1/3 translate-x-1/3" src="/images/smart-sports-agency-banner-circle.png" alt="" />
                            <img className="w-full h-full md:border-y-[1rem] md:border-r-[1rem] md:border-primary" src="/images/about.jpg" alt="" />
                        </div>
                        <div className="lg:pl-12 flex flex-col text-center md:text-left gap-y-5">
                            <h1 className="text-secondary text-2xl md:text-5xl font-semibold">A propos de nous</h1>

                            <p className="font-semibold italic text-lg">

                                « Ce n’est pas le sport qui développe un pays, mais les hommes et les femmes, grâce à l’éducation qu’on leur transmet à travers le sport. » <span className="text-secondary font-semibold">Saer SECK </span>

                            </p>
                            <p className="text-justify">
                                Créée en 2022, <span className="text-secondary font-semibold">Smart
                                Sport Agency/DRC </span>  est un cabinet d’étude et de conseil en sport regroupant des
                                consultants sportifs indépendants.
                                Nous évoluons dans le management du sport et dans le sport catalyseur de développement
                                durable. Avec des experts dans des domaines tels que : la bonne gouvernance des
                                institutions sportives, le marketing sportif, la communication sportive, le droit du
                                sport, l’économie du sport, la gestion des événements sportifs et le sport facteur des
                                objectifs de développement durable, nous répondons à tous types de besoin.
                            </p>

                            <p className="text-justify">

                            Nous élaborons et proposons des projets aux institutions sportives publiques comme privées.  Nous offrons une gestion de qualité des institutions sportives et de vos projets sportifs, etc.

                        </p>
                            <img
                                className="md:hidden w-full h-full md:border-y-[1rem] md:border-r-[1rem] md:border-primary" src="/images/smart-sport-agency-istockphoto-170173386-612x612.jpg" alt="" />
                        </div>
                    </div>
                    <div className="grid sm:grid-cols-2 gap-x-10  py-10 gap-y-10 text-center md:text-left">
                        <div className="flex flex-col gap-y-5">
                            <h1 className="text-secondary text-2xl sm:text-3xl font-bold">Nos objectifs</h1>
                            {/*<p className="p-4 md:p-0 bg-secondary md:bg-transparent text-white md:text-black text-lg">Nos différents objectifs sont :</p>*/}
                            {/*<p className="p-4 md:p-0 bg-secondary md:bg-transparent text-white md:text-black text-lg">*/}
                                <ol className="p-4 md:p-0 bg-secondary md:bg-transparent text-white text-justify md:text-black text-lg">
                                    <li>1. Nous contribuons efficacement dans le renforcement des capacités des gestionnaires du sport en République Démocratique du Congo et partout ailleurs où le besoin se présente.</li>
                                    <li>2. Nous travaillons au développement des conditions de la pratique du sport au Congo/RDC et partout ailleurs.</li>
                                    <li>3. Nous accompagnons nos partenaires dans la satisfaction de leurs ambitions.</li>
                                    <li>4. Nous sensibilisons sur les fonctions socio-éducatives du sport par des ateliers, des conférences et des programmes de formation.</li>
                                </ol>
                            {/*</p>*/}
                        </div>
                        <div className="flex flex-col gap-y-5">
                            <h1 className="text-secondary text-2xl sm:text-3xl font-bold">Notre mision</h1>
                            <p className="p-4 md:p-0 bg-secondary md:bg-transparent text-white text-justify md:text-black text-lg">
                                Reconnaissant la valeur économique, éducative et sociale que peut apporter le sport en
                                République Démocratique du Congo et dans toute l’Afrique.
                            </p>

                            <p className="p-4 md:p-0 bg-secondary md:bg-transparent text-white text-justify md:text-black text-lg">
                                <span className="font-semibold text-secondary italic">SSA/DRC </span> se fixe la
                                mission de lutter pour la pratique d’un sport inclusif et axé sur l’éducation de
                                qualité de ses pratiquants.


                            </p>
                            <p className="p-4 md:p-0 bg-secondary md:bg-transparent text-white text-justify md:text-black text-lg">
                                Combattre aux côtés des organisations internationales et non gouvernementales dans la lutte contre la délinquance, la criminalité, la faim, la discrimination, le manque d’accès à l’éducation, la mauvaise santé et à toute autre forme des inégalités qui sévissent dans nos communautés.
                                <br />Travailler pour l’effectivité du sport comme il est réellement un catalyseur aux objectifs de développement durable.



                            </p>
                        </div>

                    </div>
                </Container>
            </section>
            {/* about area ends */}

            {/* services  area starts */}
            <section id="services"
                     className="text-white bg-cover bg-fixed relative after:absolute after:inset-0 after:bg-black after:bg-opacity-40"
                     style={{backgroundImage: "url(/images/smart-sport-agency-istockphoto-1223619342-612x612.jpg)"}}>
                <div className="relative z-10 bg-black bg-opacity-30">
                    <Container className="flex flex-col gap-y-10">
                        <div className="text-center md:text-left grid sm:grid-cols-2 gap-x-10 items-center py-10">
                            <div>
                                <h1 className="text-3xl md:text-4xl lg:text-5xl 2xl:text-7xl font-semibold">Domaines d'activités</h1>
                            </div>
                            <div className="">
                                <p className="text-lg text-justify">
                                    Conseiller et accompagner les sportifs ainsi que les institutions sportives ou entreprises sponsors (sponsoring sportif) dans la conclusion de contrat sportif
                                    (contrat de prestation), contrat de sponsoring sportif suivant les règles de négociation de contrat de travail à travers une médiation efficace.
                                    Elle conseille également les personnes physiques ou morales dans tout type de contentieux sportif.
                                </p>
                            </div>

                        </div>
                    </Container>
                </div>
                <Container className="relative z-10 flex flex-wrap justify-center gap-y-6 pt-12 py-24">
                    <ServiceCard
                        title="Formation et renforcement des capacités"
                        excerpt="Des formations ciblées en renforcement des capacités pour améliorer la gestion, la performance et la durabilité dans le domaine du management."
                        slug="formation-et-renforcement-des-capacités"
                        image="/images/service-pic.jpg"
                    />
                    <ServiceCard
                        title="Elaboration et gestion des projets"
                        excerpt="Elaboration des projets de développement sportif institutionnel public et privée (fédération, clubs, etc.)."
                        slug="elaboration-et-gestion-des-projets"
                        image="/images/service-picture.jpg"
                    />
                    <ServiceCard
                        title="Gestion et planification des évènements sportifs"
                        excerpt="Organisation et coordination stratégique d'événements sportifs pour assurer leur succès et impact optimal."
                        slug="promotion-et-publicite"
                        image="/images/service-pic.jpg"
                    />
                    <ServiceCard
                        title="Conception et proposition des projets de développement communautaire par le sport"
                        excerpt="Élaboration et présentation de projets visant à utiliser le sport comme moteur de développement et de cohésion au sein des communautés."
                        slug="promotion-et-publicite"
                        image="/images/service-picture.jpg"
                    />
                    <ServiceCard
                        title="Gestion de carrière sportive"
                        excerpt="Guide et développement personnalisé de la carrière des sportifs pour optimiser leur performance et succès à long terme."
                        slug="promotion-et-publicite"
                        image="/images/service-pic.jpg"
                    />
                    {/*<ServiceCard*/}
                    {/*    title="Formation et renforcement des capacités"*/}
                    {/*    excerpt="Formation et renforcement des capacités"*/}
                    {/*    slug="formation-et-renforcement-des-capacités"*/}
                    {/*    image="/images/service-picture.jpg"*/}
                    {/*/>*/}
                    {/*<ServiceCard*/}
                    {/*    title="Résolution des litiges sportifs"*/}
                    {/*    excerpt="Résolution des litiges sportifs (dopage, etc.)"*/}
                    {/*    slug="promotion-et-publicite"*/}
                    {/*    image="/images/service-pic.jpg"*/}
                    {/*/>*/}
                    {/*<ServiceCard*/}
                    {/*    title="Accompagnement dans l’organisation"*/}
                    {/*    excerpt="Accompagnement dans l’organisation des évènements et manifestations sportives en RDC."*/}
                    {/*    slug="conseil-et-strategie"*/}
                    {/*    image="/images/service-picture.jpg"*/}
                    {/*/>*/}
                </Container>
            </section>
            {/* services area ends */}

            {/* réalisations area starts*/}
            <section id="realisations" className="bg-slate-100 pt-16 text-default">
                <Container className="text-center md:text-left flex flex-col gap-y-10 mx-0">
                    <div className="grid sm:grid-cols-2 gap-x-10 items-center pt-10 md:py-10">
                        <div className="px-8 pb-10 md:pb-0">
                            <h1 className="text-3xl md:text-4xl lg:text-5xl 2xl:text-7xl font-semibold text-secondary">Réalisations</h1>
                            <p className="text-lg mt-4 text-default text-justify">
                                Ce n’est pas le sport qui développe un pays, mais les hommes et les femmes, grâce à l’éducation qu’on leur transmet à travers le sport.
                            </p>
                        </div>
                        <div className="flex flex-wrap gap-4 py-8 md:py-0 bg-white md:bg-transparent">
                            <div className="w-fit mx-auto p-4 flex flex-col items-center justify-center gap-4">
                                <h3 className="font-bold text-secondary text-5xl">5<span className=" scale-125">+</span></h3>
                                <p className="text-xl font-semibold">Clients</p>
                            </div>
                            <div className="w-fit mx-auto p-4 flex flex-col items-center justify-center gap-4">
                                <h3 className="font-bold text-secondary text-5xl">20<span className=" scale-125">+</span></h3>
                                <p className="text-xl font-semibold">Partenaires</p>
                            </div>
                            <div className="w-fit mx-auto p-4 flex flex-col items-center justify-center gap-4">
                                <h3 className="font-bold text-secondary text-5xl">2<span className=" scale-125">+</span></h3>
                                <p className="text-xl font-semibold">Projets</p>
                            </div>
                        </div>

                    </div>
                </Container>
                <div className="text-white hidden sm:grid sm:grid-cols-2 lg:grid-cols-4">
                    <div className="relative after:absolute after:inset-0 after:bg-black after:bg-opacity-50 flex items-center justify-center h-72 w-full bg-cover bg-right text-3xl font-semibold" style={{ backgroundImage : "url(/images/smaprt-sport-agency-istockphoto-1067803368-612x6122.jpg)"}}>
                        <span className="relative z-20">Travail</span>
                    </div>
                    <div className="relative after:absolute after:inset-0 after:bg-black after:bg-opacity-50 flex items-center justify-center h-72 w-full bg-cover bg-center text-3xl font-semibold" style={{ backgroundImage : "url(/images/smart-sport-agency-istockphoto-1339797427-612x612.jpg)"}}>
                        <span className="relative z-20">Discipline</span>
                    </div>
                    <div className="relative after:absolute after:inset-0 after:bg-black after:bg-opacity-50 flex items-center justify-center h-72 w-full bg-cover bg-center text-3xl font-semibold" style={{ backgroundImage : "url(/images/smart-sport-agency-istockphoto-1204020482-612x612.jpg)"}}>
                        <span className="relative z-20">Ethique</span>
                    </div>
                    <div className="relative after:absolute after:inset-0 after:bg-black after:bg-opacity-50 flex items-center justify-center h-72 w-full bg-cover bg-center text-3xl font-semibold" style={{ backgroundImage : "url(/images/smart-sport-agency-istockphoto-475077996-612x612.jpg)"}}>
                        <span className="relative z-20">Concentration</span>
                    </div>
                </div>
            </section>
            {/* réalisations area ends */}


            {/* team section */}
            <section className="text-default bg-primary bg-opacity-70 md:bg-transparent">
                <div className="container mx-auto px-4 md:px-0 py-24">
                    <h1 className="text-white md:text-secondary text-3xl md:text-4xl lg:text-5xl 2xl:text-7xl font-semibold text-center">Equipe</h1>
                    <p className="font-semibold italic text-lg text-white md:text-default sm:text-lg text-center max-w-xl mx-auto mt-4">
                        « Vous satifaire notre priorité »
                    </p>
                    <div className="flex flex-wrap justify-center gap-8 mt-10">
                        <TeamMemberCard name="Obed MUTENGA" position="Directeur général" image="/images/team/obed.jpeg" />
                        <TeamMemberCard name="Asnath ILUNGA" position="Secrétaire Générale & Responsable de Communication" image="/images/team/smart-sport-agency-ilunga-miozi-asnath.jpg" />
                        <TeamMemberCard name="Jean LUBUANGA" position="Directeur Financier" image="/images/team/Jean.jpeg" />

                        <TeamMemberCard name="Murdoch KALONJI" position="Directeur Projets & Webmaster" image="/images/team/murdoch.jpg" />

                        {/* <TeamMemberCard name="MUTENGA TSHYEDI Obed" position="Directeur général" image="/images/team/smart-sport-agency-mutenga-tshiyedi-obed.jpg" />
                        <TeamMemberCard name="KALONJI MBAYA Michée" position="Financier" image="/images/team/smart-sport-agency-kalonji-mbala-michee.jpg" />
                        <TeamMemberCard name="KAMBAMBA MAKABILA Ledjo" position="Chargé des projets" image="/images/team/smart-sport-agency-kabamba-makabila-ledjo.jpg" />
                        <TeamMemberCard name="ILUNGA MIOZI Asnath" position="Secrétaire générale et chargée des communicationions" image="/images/team/smart-sport-agency-ilunga-miozi-asnath.jpg" /> */}
                    </div>
                </div>
            </section>
             {/* contact-form area starts */}
            <section id="contact" className="text-white bg-cover bg-center relative after:absolute after:inset-0 after:bg-black after:bg-opacity-60 py-28" style={{ backgroundImage : "url(/images/istockphoto-143921828-612x612.jpg)"}}>
                <Container className="relative z-10">
                    <h1 className="text-center text-3xl md:text-4xl lg:text-5xl 2xl:text-7xl font-semibold">Contact</h1>
                    <div className="hidden mt-14 md:flex flex-wrap flex-row justify-center items-center gap-y-12 gap-x-6 md:gap-x-16 ">
                            <a href="tel:+243844952771" className="flex flex-col items-center gap-y-5">
                                <i className="fa-solid fa-phone text-3xl text-white" />
                                <span className="">+243 844 952 771</span>
                            </a>
                            {/*<div className="flex flex-col items-center gap-y-5">*/}
                            {/*    <i className="fa-solid fa-location-dot text-3xl text-white" />*/}
                            {/*    <span className="">377 Wakami, Kintambo</span>*/}
                            {/*</div>*/}
                            <a href="mailto:contact@ssadrc.com" className="flex flex-col items-center gap-y-5">
                                <i className="fa-regular fa-envelope text-3xl text-white" />
                                <span className="">contact@ssadrc.com</span>
                            </a>
                    </div>
                    <MyForm
                        className='flex flex-wrap justify-between mt-8 md:mt-14 max-w-2xl mx-auto'
                        onSubmit={ async (fields,)  => {
                            setIsSubmitting(true)
                            console.log("form ==> ", fields)
                            try {
                               const result = await emailjs.send(
                                    'service_qf275h2',
                                    'template_57dznrp',
                                   fields,
                                    {
                                        publicKey: 'osiNwt610-fXn1yTV',
                                    },
                                );
                                console.log('SUCCESS! ===>', result);
                                setIsSubmitting(false)
                            } catch (err) {
                                if (err instanceof EmailJSResponseStatus) {
                                    console.log('EMAILJS FAILED...', err);
                                    return;
                                }

                                console.log('ERROR', err);
                            }
                        }}
                        fields={{
                            name : {
                                id : 'fullname',
                                placeholder : 'Entrez votre nom',
                                label : 'Nom',
                                required : true,
                                onEmptyErrorMessage : 'Champ obligatoire',
                                onInvalidErrorMessage : 'At last 10 character',
                            },
                            email : {
                                id : 'email',
                                placeholder : 'Entrez votre email',
                                label : 'Email',
                                required : true,
                                onEmptyErrorMessage : 'Champ obligatoire',
                                onInvalidErrorMessage : 'email is not valid',
                            },
                            phone : {
                                id : 'phone',
                                placeholder : 'Entrez le numéro de téléphone',
                                label : 'Téléphone',
                                required : true,
                                onEmptyErrorMessage : 'Champ obligatoire',
                                onInvalidErrorMessage : '',
                            },
                            subject : {
                                id : 'subject',
                                label : 'Sujet',
                                placeholder : "Choisir le sujet",
                                required : true,
                                onEmptyErrorMessage : 'Champ obligatoire',
                                extraData : { options : [{label :"S'informer", value : "001 Information"}, {label : "Question", value : "002 Question"}, {label:"Autre", value:"003 Autre"}] },
                                renderFieldComponent : InputFieldCustomSelect
                            },
                            message : {
                                label : "Message",
                                placeholder : "Ecrivez votre message",
                                required : true,
                                onEmptyErrorMessage : 'Champ obligatoire',
                                onInvalidErrorMessage : "too short and invalid",
                                renderFieldComponent : InputFieldTextArea
                            }

                        }}

                    >

                        <div className="flex-1 flex justify-end gap-4 my-4">

                            <button
                                className="w-full disabled:events-none flex gap-2 items-center justify-center text-center bg-primary hover:bg-primary-deep py-5 rounded-md text-white text-2xl"
                                disabled={isSubmitting}
                            >
                                <span>Envoyer</span>
                                 { isSubmitting && <span className='fa-solid fa-circle-notch animate-spin' />   }
                            </button>
                        </div>

                    </MyForm>
                </Container>
            </section>
            {/* contact-form area ends */}

            {/* clients area starts*/}
            <section className="hidden md:block bg-slate-100 py-24">
                <Container className="flex flex-col gap-y-10">
                    <div className="grid sm:grid-cols-2 gap-x-10 items-center">
                        <div>
                            <h1 className="text-secondary text-4xl lg:text-5xl 2xl:text-7xl font-semibold">Ils nous ont fait confiance</h1>
                        </div>
                        <div>
                            <p className="text-lg text-default text-justify">
                                Le sport peut enseigner des valeurs telles que: l'équité, le respect, la maîtrise, le patriotisme, le travail d'équipe, etc.
                                Conscient de la fonction socio-éducative du sport, nous, <span className="font-semibold text-secondary italic">SSA/DRC</span>,
                                avons décidé de nous lancer  dans l’encadrement de la jeunesse sportive dans le but de les aider à réussir leurs carrières sportives.
                            </p>
                        </div>

                    </div>
                    <div className="flex flex-wrap justify-center gap-8">
                        {/*<ClientLogo title="Sport and Justice" image="/images/logos/sportjustice.jpeg" link="" />*/}
                        <ClientLogo title="Athletes Sphere Management" image="/images/logos/athlets.jpeg" link="" />
                        {/*<ClientLogo title="Fifa CIES" image="/images/logos/fifacies.jpeg" link="" />*/}
                        {/*<ClientLogo title="Real Betis" image="/images/logos/realbetis.jpeg" link="" />*/}
                        <ClientLogo title="Tinmahn Sport" image="/images/logos/tinmahn.jpeg" link="" />
                    {/*    <ClientLogo title="ADIT" image="/images/logos/smart-sports-agency-logo.png" link="http://www.digital-invest-cooprate.com" />*/}
                    {/*    <ClientLogo title="ADIT" image="/images/logos/smart-sports-agency-logo.png" link="http://www.digital-invest-cooprate.com" />*/}
                    {/*    <ClientLogo title="ADIT" image="/images/logos/smart-sports-agency-logo.png" link="http://www.digital-invest-cooprate.com" />*/}

                    {/*    <ClientLogo title="ADIT" image="/images/logos/smart-sports-agency-logo.png" link="http://www.digital-invest-cooprate.com" />*/}
                    {/*    <ClientLogo title="ADIT" image="/images/logos/smart-sports-agency-logo.png" link="http://www.digital-invest-cooprate.com" />*/}
                    {/*    <ClientLogo title="ADIT" image="/images/logos/smart-sports-agency-logo.png" link="http://www.digital-invest-cooprate.com" />*/}
                    </div>
                </Container>
            </section>

            {/* clients area ends */}
            <section className="md:hidden py-24">
                <Container className="text-center">
                    <h1 className="text-secondary text-4xl lg:text-5xl 2xl:text-7xl font-semibold">Ils nous font confiance</h1>
                    <p className="text-lg mt-4 mb-8 text-default">
                        Le sport peut enseigner des valeurs telles que: l'équité, le respect, la maîtrise, le patriotisme, le travail d'équipe, etc.
                    </p>
                    <Slider
                        className="flex flex-wrap justify-center gap-8"
                        // slidesPerView={2}
                        // autoplay
                        // spaceBetween={10}
                        loop
                        grabCursor
                        autoplay={{ delay : 2500, disableOnInteraction : false, pauseOnMouseEnter : true}}
                        slidesPerView={2}
                        spaceBetween={10}
                        // pagination={{clickable : true}}
                    >

                        <ClientLogo title="Tinmahn Sport" image="/images/logos/tinmahn.jpeg" link="" />
                        <ClientLogo title="Athletes Sphere Management" image="/images/logos/athlets.jpeg" link="" />

                        {/*<ClientLogo title="Tinmahn Sport" image="/images/logos/tinmahn.jpeg" link="" />*/}

                        {/*<ClientLogo title="ADIT" image="/images/logos/smart-sports-agency-logo.png" link="http://www.digital-invest-cooprate.com" />*/}
                        {/*<ClientLogo title="ADIT" image="/images/logos/smart-sports-agency-logo.png" link="http://www.digital-invest-cooprate.com" />*/}
                        {/*<ClientLogo title="ADIT" image="/images/logos/smart-sports-agency-logo.png" link="http://www.digital-invest-cooprate.com" />*/}
                        {/*<ClientLogo title="ADIT" image="/images/logos/smart-sports-agency-logo.png" link="http://www.digital-invest-cooprate.com" />*/}
                        {/*<ClientLogo title="ADIT" image="/images/logos/smart-sports-agency-logo.png" link="http://www.digital-invest-cooprate.com" />*/}
                        {/*<ClientLogo title="ADIT" image="/images/logos/smart-sports-agency-logo.png" link="http://www.digital-invest-cooprate.com" />*/}
                        {/*<ClientLogo title="ADIT" image="/images/logos/smart-sports-agency-logo.png" link="http://www.digital-invest-cooprate.com" />*/}
                        {/*<ClientLogo title="ADIT" image="/images/logos/smart-sports-agency-logo.png" link="http://www.digital-invest-cooprate.com" />*/}
                        {/*<ClientLogo title="ADIT" image="/images/logos/smart-sports-agency-logo.png" link="http://www.digital-invest-cooprate.com" />*/}
                        {/*<ClientLogo title="ADIT" image="/images/logos/smart-sports-agency-logo.png" link="http://www.digital-invest-cooprate.com" />*/}

                    </Slider>
                </Container>
            </section>

        </>
    )
};
